<template>
  <v-container class="bg-section mb-16 mt-16" fluid>
    <div class="bg-cards mt-12">
      <v-row class="justify-center align-center text-center pa-8">
        <v-col :md="1">
          <v-img
            :src="require(`@/assets/icons/icon-cbd.png`)"
            contain
            height="80"
            class="my-6"
          />
        </v-col>
        <v-col cols="12" :md="4">
          <div class="mr-16 text-center">
            <h3 class="title-main">Automatiza la gestión de tus ventas</h3>
          </div>
        </v-col>
        <v-col cols="12" :md="2" class="mx-2 cont-msg">
          <h6>Genera presupuestos, pedidos y albaranes con un simple clic.</h6>
        </v-col>
        <v-col cols="12" :md="2" class="mx-2 cont-msg">
          <h6>Crea y envía tus facturas al instante.</h6>
        </v-col>
        <v-col cols="12" :md="2" class="mx-2 cont-msg">
          <h6>Genera tus remesas y súbelas a tu banco fácilmente.</h6>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "InventarioSubBanner",

  data: () => ({}),
};
</script>

<style scoped>
.bg-cards {
  background: #fdfdfd;
}

.bg-card {
  background: #ffffff;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
  border-radius: 14px;
}

.title-main {
  font-size: 20px;
}

.cont-msg {
    border-right: solid rgba(196, 196, 196, 0.4);
}
</style>
