<template>
  <v-container class="bg-section" fluid>
    <v-row class="justify-center align-center mt-16">
      <v-col cols="12" :md="6" class="mt-4">
        <div class="mx-4 ml-lg-16">
          <h6 class="ml-lg-16 pl-lg-16">ORGANIZA TU ALMACÉN</h6>
          <h2 class="b-6 ml-lg-16 pl-lg-16">Ten siempre controlado tu almacén</h2>
          <p class="ml-lg-16 pl-lg-16">
            Organiza tus productos por secciones, familias y asigna las
            propiedades que necesites en todo momento. Selecciona la tarifa
            deseada para cada producto: general, tarjetas de fidelidad,
            descuentos, etc.
          </p>
        </div>
      </v-col>
      <v-col cols="12" :md="6" class="cont-img">
        <v-img
          :src="require('@/assets/inventarios/stock.png')"
          contain
          height="280"
          class="ml-lg-12"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ControlOnStock",

  data: () => ({}),
};
</script>

<style scoped>
.cont-img {
  text-align-last: left;
}
</style>
