<template>
  <v-container class="bg-section mb-16" fluid>
    <v-row class="justify-center align-center mb-14">
      <v-col cols="10" :md="6" class="cont-img">
        <v-img
          :src="require('@/assets/inventarios/photo-stock.png')"
          contain
          height="280"
          class="mr-lg-16"
        />
      </v-col>
      <v-col cols="10" :md="6" class="mt-4">
        <div class="mr-lg-16 mb-14">
          <h6 class="mr-lg-16 pr-lg-16">CONTROLA TU ALMACÉN</h6>
          <h2 class="b-6 mr-lg-16 pr-lg-16">Controla tu inventario en tiempo real</h2>
          <p class="mr-lg-16 pr-lg-16">
            Recibe notificaciones cuando tu almacén está bajo mínimos o cuando
            tienes demasiado stock. Visualiza la información actualizada de tus
            ventas y consulta el histórico para hacer previsiones.
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Stock",

  data: () => ({}),
};
</script>

<style scoped>
.cont-img {
  text-align-last: right;
}
</style>
