<template>
  <v-container class="mb-16" fluid>
    <v-row class="mt-16">
      <v-col cols="12" :lg="5" class="ml-lg-14">
        <div class="mb-8 mx-4 ml-lg-16 mt-16 text-center justify-center align-center">
          <h1 class="mb-6">
            El inventario de tu negocio en una única aplicación
          </h1>
          <p class="pb-8 mt-8">
            Optimiza la gestión de tu tienda de la forma más sencilla, completa
            y automatizada.
          </p>
        </div>
        <v-row class="justify-center align-center">
          <div class="text-center mx-lg-12">
            <v-btn rounded x-large class="mainButton" dark>
              Probar Gratis
            </v-btn>
          </div>
          <div class="text-center ml-sm-10">
            <v-btn rounded x-large color="primaryLint" class="red--text" dark>
              Contactarme
            </v-btn>
          </div>
        </v-row>
      </v-col>
      <v-col cols="12" :lg="6">
        <div class="ml-lg-16 mt-0 mt-sm-16 mt-lg-0">
          <v-img
            :src="require('@/assets/inventarios/inventario-banner.png')"
            class=""
            contain
            height="480"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "BannerInventarios",

  components: {},
  data: () => ({}),
};
</script>

<style scoped>
.bg-card {
  background: #141e56;
  border-radius: 20px;
}
.icon-banner {
  background-position: left;
}
</style>
