<template>
  <v-container class="pa-0" fluid>
    <BannerInventarios />
    <ControlOnStock />
    <InventarioSubBanner />
    <Stock />
    <AllYouNeed />
    <CTAInv />
  </v-container>
</template>

<script>
  import BannerInventarios from '../components/sections/BannerInventarios.vue'
  import ControlOnStock from '../components/sections/ControlOnStock.vue'
  import InventarioSubBanner from '../components/sections/InventarioSubBanner.vue'
  import Stock from '../components/sections/Stock.vue'
  import AllYouNeed from '../components/sections/AllYouNeed.vue'
  import CTAInv from '../components/sections/CTAInv.vue'

  export default {
    name: 'Inventarios',

    components: {
      BannerInventarios,
      ControlOnStock,
      InventarioSubBanner,
      Stock,
      AllYouNeed,
      CTAInv
    },
  }
</script>